@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/theme/public/assets/fonts/opensans/open-sans-v35-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
    font-display: swap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/theme/public/assets/fonts/opensans/open-sans-v35-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
