#map {
    @apply h-96 w-full -mb-12 z-10 ;

}
.leaflet-tile-container {
    @apply grayscale opacity-70;
}

.leaflet-control-attribution {
    @apply grayscale opacity-70 hover:opacity-100 hover:grayscale-0;
}


.leaflet-popup-content p {
    padding: 0;
}