@tailwind base;
@tailwind components;
@tailwind utilities;

.border-red * {
    border: 1px solid red;
}


@import 'dev.css';
@import 'tippy.css';
@import 'fonts.css';
@import 'swiper-bundle.css';
@import 'leaflet.css';



[x-cloak] {
    display: none !important;
}

html, body {
    min-width: 320px !important;
    height: 100.1%;
    font-family: 'Open Sans';
}

*:focus {
    outline: none;
}

#content {
    @apply pt-24 md:pt-32 xl:pt-48
}


.container > .container  {
    @apply !px-0
}


@layer base {

    h1,h2,h3,h4,h5,h6 {
    }

    h1 {
        @apply text-xl lg:text-2xl xl:text-3xl font-bold text-primary-800 pb-6
    }
    h2 {
        @apply text-base lg:text-2xl pb-3;
    }
    h3 {
        @apply text-xl xl:text-xl  font-bold pb-3 ;
    }
    h4 {
        @apply text-xl xl:text-2xl;
    }
    h5 {
        @apply text-xl;
    }
    h6 {
        @apply text-base;
    }
    a {
        @apply text-neutral-500 hover:text-black underline font-bold;
    }
    p {
        @apply pb-8 text-neutral-500;
    }
}

#content ul { @apply list-disc pl-6 pb-4 }

/* Accordion */

.accordion-wrapper {
    @apply shadow-md w-full rounded-t-md rounded-b-md  ;
}
.accordion-wrapper ul {
    @apply border-0   !list-none !m-0 !p-0	;
}

.accordion-header {
        @apply w-full leading-5 font-bold p-3 pr-6 text-left text-white bg-secondary-500 font-bold text-base hover:bg-gray-400 hover:bg-secondary-800 transition-colors;
}
.accordion-header.active {
    @apply bg-secondary-800;
}
.accordion-wrapper li:first-child .accordion-header {
    @apply rounded-t-md;
}
.accordion-content {
    @apply relative bg-neutral-100 overflow-hidden transition-all max-h-0 duration-700;
}
.accordion-content div{
    @apply px-4 py-6 text-base ;
}
.accordion-wrapper li:last-child .accordion-header:not(.active) {
    @apply rounded-b-md;
}
.accordion-wrapper li:last-child .accordion-content {
    @apply rounded-b-md ;
}

/* Buttons */

.btn-primary {
    @apply inline-flex items-center text-base font-normal no-underline py-2 px-4 rounded bg-secondary-500 text-white hover:bg-secondary-800 hover:text-white;
}
.btn-secondary {
    @apply inline-flex items-center text-base font-normal no-underline py-2 px-4 rounded bg-secondary-500 text-white hover:bg-secondary-800 hover:text-white;

}
.textlink {
    @apply inline-flex items-center text-base font-normal text-secondary-500 hover:text-black;
}



/* spotlight */
#spotlight { /* main font styles, background */ }
.spl-page { /* current page (toolbar) */ }
.spl-fullscreen { /* button fullscreen (toolbar) */ }
.spl-autofit { /* button autofit (toolbar) */ }
.spl-zoom-out { /* button zoom out (toolbar) */ }
.spl-zoom-in { /* button zoom in (toolbar) */ }
.spl-theme { /* button theme (toolbar) */ }
.spl-play { /* button autoplay (toolbar) */ }
.spl-download { /* button download (toolbar) */ }
.spl-close { /* button close (toolbar) */ }
.spl-prev { /* button page prev */ }
.spl-next { /* button page next */ }
.spl-spinner { /* preloading spinner */ }
.spl-spinner.spin { /* show spinner */ }
.spl-spinner.error { /* show loading error */ }
.spl-title {
    @apply container;
}
.spl-description {
    @apply container pb-5;
}
.spl-button { /* button footer */ }
.spl-header { /* the header wrapping element */ }
.spl-footer { }



footer {
    @apply text-sm text-primary-800;
}
footer a {
    @apply no-underline text-sm font-normal text-primary-500 hover:underline
}

footer p {
    @apply pb-0;
}


.langchoice {
    @apply flex flex-wrap justify-center ml-8
}

.langchoice a {
    @apply font-normal float-left flex items-center justify-between flex-wrap gap-1 text-xs lg:text-sm lg:gap-2 text-neutral-500 hover:text-black transition transition-colors no-underline
}

.langchoice  a .flag{
    @apply w-4 lg:w-5  h-auto p-0 m-0
}

.langchoice  a:hover .flag{
    @apply drop-shadow-md
}













#rex-yform {
    @apply pb-10 ;
}


#rex-yform label {
    @apply text-right
}


.alert-danger {
    @apply pt-2 pr-2 pl-4 my-2 rounded border border-red-500 bg-red-500;
}

.alert-danger ul {
    @apply m-0 p-0 list-none border-none text-white font-bold ;
}

.alert-danger ul li {
    @apply relative list-none border-none text-sm  md:text-base my-3 mx-0 pl-3 text-white font-bold;
}
.alert-danger ul li:before {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="white"><path fill-rule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" /></svg>');
    @apply inline-block absolute top-0 -left-3 w-5 h-5 md:top-0.5;
}

.form-group {
    @apply mb-4 w-full flex flex-wrap md:flex-nowrap ;
}
.form-group.has-error .control-label {
    @apply bg-red-500 text-white font-bold;
}
.form-group.has-error .form-control {
    @apply border-red-500;
}
.form-group .control-label {
    @apply text-gray-800 text-sm p-2 rounded-t w-full md:w-64 md:rounded-l md:rounded-r-none text-right pr-4 bg-neutral-50 ;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.formcheckbox {
    @apply h-20  w-full block
}

.form-group input,
.form-group textarea{
    @apply rounded-b rounded-t-none border border-gray-200 border-t-0 px-4 py-3 text-base text-gray-600 focus:text-black w-full md:grow md:rounded-r md:rounded-l-none md:border md:border-l-0 ;
}
.form-group input:focus,
.form-group textarea:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.form-group .help-block {
    @apply -z-10 text-xs font-bold p-2 pt-4 w-full rounded-b rounded-t-none -mt-2 bg-gray-200 md:hidden;
}

.yform .btn-primary {
    @apply -mt-10 bg-secondary-500 py-3 px-3 leading-tight rounded-md flex hover:bg-secondary-800;
}


.yform a {
    @apply text-secondary-500 font-bold text-sm hover:text-black no-underline ;
}


.offcanvas-content p {
    @apply pb-0 pt-0 m-0 ;
}
.cookie_hinweis  {
    @apply w-full border p-0;
}

.cookie_hinweis a {
    @apply w-full  bg-gray-300 p-10 inline-block text-center hover:bg-gray-400 transition-colors hover:cursor-pointer;
}
